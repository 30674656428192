/// <reference path="../src/defs/iscroll.d.ts" />

import * as system from 'durandal/system';
import * as app from 'durandal/app';
import * as viewLocator from 'durandal/viewLocator';
import * as viewEngine from 'durandal/viewEngine';
import './index.html';
import '../css/app.less';
import '../src/views/_css/app.scss';
import '../src/views/_css/skin.scss';
import { UrlState, AppConfig } from '@luxms/bi-core';
const skin: any = require('../src/skins/skin.json');

declare const BISettings: any;
declare const DEBUG: any;
system.debug(DEBUG);

// logo
console.log('\n\n %c %c %c %c %c Luxms BI \n',
            'background: #E07921;margin: 0 2px;padding:0px 0 6px 0; border-radius: 5px;',
            'background: #4AB6E8; margin: 0 2px;padding:7px 0 6px 0; border-radius: 5px;',
            'background: #4F4F9B; margin: 0 2px;padding:15px 0 6px 0; border-radius: 5px;',
            'background: #AA6FAC; margin: 0 2px;padding:20px 0 6px 0; border-radius: 5px;',
            'background: transparent; color: #F05045; padding:5px 0; font-weight: bold; font-size:200%;');

// initialize appConfig and UrlState
AppConfig.getInstance().setAppConfig(BISettings);
if (BISettings.features?.find(el => el.hasOwnProperty("entryPoint"))  && location.hash == "") {
  const entryPoint = BISettings.features.find(el => el.hasOwnProperty("entryPoint"))['entryPoint'];
  UrlState.getInstance().navigate(entryPoint);
} else if (skin.entryPoint && location.hash == "") {
  UrlState.getInstance().navigate(skin.entryPoint);
}


(window as any).applyTheme = (theme: string) => {
  if (theme !== 'dark' && theme !== 'light') theme = 'light';

  document.body.className = document.body.className.split(' ').filter(s => !s.startsWith('theme-')).join(' ') + ` theme-${theme}`;
  let iframes = $('iframe').toArray();
  iframes.forEach(iframe => {
    const src = iframe.getAttribute('src');
    const newSrc = src.replace(/theme=(\w+)/, `theme=${theme}`);
    if (src !== newSrc) {
      iframe.setAttribute('src', newSrc);
    }
  });
  window.localStorage.setItem('theme', theme);
};

(window as any).toggleTheme = () => {
  let theme = document.body.className.match(/theme-(\w+)/) ? RegExp.$1 : 'light';
  if (theme === 'light') theme = 'dark';
  else theme = 'light';
  (window as any).applyTheme(theme);
};

(window as any).applyTheme(window.localStorage.getItem('theme'));


async function execScript(path) {
  const r = await fetch(path);
  const text = await r.text();
  if (navigator.appName == 'Microsoft Internet Explorer') {
    (window as any).execScript(text);
  } else {
    const obj = document.createElement('script');
    const node = document.createTextNode(text);
    document.body.appendChild(obj);
    obj.appendChild(node);
  }
}

const APP_LOCALE: string = AppConfig.getLocale();
const $html = $('html');

switch (APP_LOCALE) {
  case 'ru-RU':
    $html.addClass('ru');
    break;
  case 'en-US':
    $html.addClass('en');
    break;
}

var viewEngine_createFallbackView = viewEngine.createFallbackView;
(viewEngine as any).createFallbackView = function (viewId, requirePath, err) {
  if (requirePath.match(/dash-context-menu\/.+\.svg/)) {
    return viewEngine.createView('assets/icons/dash-context-menu/unknown.svg?.html');
  }
  return viewEngine_createFallbackView.apply(this, arguments);
};


(function () {
  var currentBtn = null;

  function calcAngles(event) {
    var w = $(event.target).width(), h = $(event.target).height();
    var dx = 2 * event.offsetX / w - 1, dy = 2 * event.offsetY / h - 1;
    return [Math.round(-5 * dy), Math.round(10 * dx)];
  }

  $(document).on('mousedown', '.ug.btn, .ug.pressable', function (event) {
    var angles = calcAngles(event);
    if (currentBtn !== event.target) {
      $(currentBtn).css('transform', 'none');
    }
    currentBtn = event.target;
    $(currentBtn).css('transform', 'rotateX(' + angles[0] + 'deg) rotateY(' + angles[1] + 'deg)');
  });

  $(document).on('mousemove', '.ug.btn, .ug.pressable', function (event) {
    if (currentBtn === event.target) {
      var angles = calcAngles(event);
      $(currentBtn).css('transform', 'rotateX(' + angles[0] + 'deg) rotateY(' + angles[1] + 'deg)');
    }
  });

  $(document).on('mouseout mouseup', '.ug.btn, .ug.pressable', function (event) {
    if (currentBtn) {
      $(currentBtn).css('transform', 'none');
      currentBtn = null;
    }
  });

  $(document).on('dragstart', '.ug.btn', function () {
    return false;
  });
})();


$(document).on('mousedown', 'button.material, a.material, .button.material', function (e) {
  var target = e.target;
  if (!$(target).hasClass('material')) {
    target = $(target).closest('.material')[0];
  }
  if (!target) return false;
  var rect = target.getBoundingClientRect();
  // var ripple: any = target.querySelector('.ripple');
  var ripple: any = document.querySelector('.ripple');
  $(ripple).remove();
  ripple = document.createElement('span');
  ripple.className = 'ripple';
  ripple.style.height = ripple.style.width = Math.max(rect.width, rect.height) + 'px';
  target.appendChild(ripple);
  var top = e.pageY - rect.top - ripple.offsetHeight / 2 -  document.body.scrollTop;
  var left = e.pageX - rect.left - ripple.offsetWidth / 2 - document.body.scrollLeft;
  ripple.style.top = top + 'px';
  ripple.style.left = left + 'px';
  window.setTimeout(() => $(ripple).remove(), 750);
  return false;
});
$(document).on('mouseleave', 'button.material, a.material, .button.material', function (e) {
  var ripple: any = document.querySelector('.ripple');
  $(ripple).remove();
});

{ // global tooltip
  let $tooltip = null;
  let destroyTimer = null;

  const destroyTooltip = () => {
    destroyTimer = window.setTimeout(() => {
      if ($tooltip) $tooltip.remove();
      $tooltip = null;
    }, 500);
  };

  const cancelDestroy = () => {
    if (destroyTimer) window.clearTimeout(destroyTimer);
    destroyTimer = null;
  };

  const fixValueBetween = (minVal, val, maxVal) => {
    if (val < minVal) val = minVal;
    if (val > maxVal) val = maxVal;
    return val;
  };

  $(document).on('mouseenter', '[data-tooltip]', (event) => {
    let $el = $(event.target);
    if (!$el.data('tooltip')) $el = $el.parents('[data-tooltip]');
    if (!$el.data('tooltip')) return;
    cancelDestroy();

    const tooltip: string = String($el.data('tooltip'));
    let tooltipDirection: string = String($el.data('tooltip-direction'));

    $tooltip = $tooltip || $(`<div class="tooltip tooltip--global" style="position: absolute;  white-space: pre-wrap; overflow-wrap: break-word; padding:10px;  min-height: 30px;max-width: 100%; "></div>`);
    const bcr: ClientRect = $el[0].getBoundingClientRect();
    const ecx = (bcr.left + bcr.right) >> 1;
    const ecy = (bcr.top + bcr.bottom) >> 1;

    if (tooltipDirection === 'vertical') {
      if (bcr.top + bcr.height + 100 > document.body.offsetHeight) {          // 100 px approx for tooltip
        tooltipDirection = 'top';
      } else {
        tooltipDirection = 'bottom';
      }
    }

    let left: any,
      right: any,
      top: any,
      bottom: any,
      width = 180,
      transform: string,
      className: string = `tooltip--${tooltipDirection}`;

    const docWidth = $(document).width(), docHeight = $(document).height();
    switch (tooltipDirection) {
      case 'right':
        left = bcr.right;
        top = ecy;
        transform = 'translate(0, -50%)';
        break;
      case 'top':
        left = ecx;
        bottom = docHeight - bcr.top + 10;
        transform = 'translate(-50%, 0)';
        break;
      case 'left':
        right = docWidth - bcr.left + 20;
        top = ecy;
        transform = 'translate(0, -50%)';
        break;
      default:   // bottom
        left = ecx;
        top = bcr.bottom + 10;
        transform = 'translate(-50%, 0)';
        className = 'tooltip--bottom';
        break;
    }

    $tooltip.removeClass('top');
    $tooltip.removeClass('left');
    $tooltip.removeClass('right');
    $tooltip.removeClass('bottom');
    $tooltip.addClass(className);

    const tooltipCss = {
      'left': left != null ? fixValueBetween(5, left, docWidth - 50 - (width >> 2)) : 'auto',
      'top': top != null ? fixValueBetween(5, top, docHeight - 30) + 'px' : 'auto',
      'right': right != null ? fixValueBetween(5, right, docWidth - 5 - (width >> 2)) + 'px' : 'auto',
      'bottom': bottom != null ? fixValueBetween(5, bottom, docHeight - 30) + 'px' : 'auto',
      'width': width + 'px',
      'border': '1px solid #cccccc',
      'box-shadow': '0 0 10px rgba(0,0,0,0.5)',
      'z-index': 1001,
      'opacity': 1,
      'background': 'rgba(255,255,255, 0.9)',
      'border-radius': '2px',
      transform,
    };

    $tooltip.css(tooltipCss);
    $tooltip.text(tooltip);

    // if just created
    if (!$tooltip.parent()[0]) {
      $(document.body).append($tooltip);
    }
  });

  $(document).on('mouseleave', '[data-tooltip]', (event) => {
    destroyTooltip();
  });
} // global tooltip


ko.bindingHandlers.iscroll = {
  init: function (element, valueAccessor, allBindingsAccessor, viewModel) {
    const cfg = valueAccessor();
    import('iscroll').then((IScroll: any) => {
      IScroll = IScroll.default || IScroll;
      let _scroll = new IScroll(element, cfg);
      let timer = window.setInterval(() => _scroll.refresh(), 1000);

      ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
        window.clearInterval(timer);
        _scroll.destroy();
        _scroll = null;
      });
    });
  },
};


moment.locale(AppConfig.getLocale().substring(0, 2));

$('html').attr('lang', AppConfig.getLocale().substring(0, 2));

(app as any).title = AppConfig.getProjectTitle();


app.start().then(async function () {
  viewLocator.useConvention('app', 'src');

  // load locale
  await execScript(`assets/locales/${APP_LOCALE}/messages.js`);

  const ShellVC = (await import('../src/view-controllers/ShellVC')).ShellVC;
  const shell = (await import('../src/views/Shell')).shell;

  const shellVC = new ShellVC();

  //
  // TODO: create shell view instance
  //

  // debug
  (window as any).shell = shell;

  shellVC.subscribeUpdatesAndNotify((vmShell) => {
    shell.setVM(vmShell);
    // debug
    (window as any).__vm = vmShell;
  });


  app.setRoot(shell, null, document.getElementById('applicationHost'));
  $('#main-view-loading').remove();
});

